import React from 'react'
import dev from '../Assets/images/dev3.svg'
import './Home.css'
import ButtonP from '../Components/ButtonP'
import ButtonS from '../Components/ButtonS'
import { Link } from 'react-router-dom'
import { Helmet } from 'react-helmet'
const Home = () => {
  window.scrollTo(0, 0);
  return (
    <>
      <Helmet>
        <meta charSet='utf-8' />
        <title>Arjun Madavoor</title>
        <meta
          name='description'
          content="Welcome! I am Arjun, a DevOps engineer and web developer. Here, you'll find an overview of my skills, projects, and blog posts, highlighting my expertise in these fields.. Whether you are looking for a programmer
            to take your project to the next level, or simply want to learn more
            about the latest developments in the industry, my portfolio website
            is the perfect place to start."
        />
        <meta
          name='keywords'
          content='portfolio, Arjun madavoor, arjun, arjun mdr, skill,blog,how to, programmer'
        />
      </Helmet>
      <div className='home-div' id='home'>
        <div className='title-div'>
          <div>
            <h1>Hi,</h1>
          </div>
          <div>
            <h1>
              I'am <span className='title-name'>Arjun</span>
            </h1>
          </div>
          <div>
            <h1>DevOps Engineer</h1>
          </div>
          <div className='btn-div'>
            <Link to='/blogs'>
              <ButtonP buttonText={'Blog'} />
            </Link>
            <Link to='/about'>
              <ButtonS buttonText={'Explore'} />
            </Link>
            {/* <Link to='/projects'>
              <ButtonP buttonText={'Projects'} />
            </Link> */}
          </div>
        </div>

        <div className='image-div'>
          <img className='dev-img' src={dev} alt='' />
        </div>
      </div>
    </>
  );
}

export default Home