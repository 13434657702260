import React from 'react'
import { Link } from 'react-router-dom';
import Footer from '../Components/Footer';
import Timeline from '../Components/Timeline';
import './About.css'
import Skills from './Skills';
const About = () => {
  return (
    <>
      <div className='about-div' id='about'>
        <div className='about-card'>
          <div className='about-left'>
            <h4>About Me</h4>
            <p>
              I am a self-taught DevOps Engineer and Web Developer with a solid
              foundation in front-end development and computer engineering. I
              completed my diploma in Computer Engineering in 2021 and currently
              serve as a DevOps Engineer at Actionfi. My expertise encompasses a
              diverse array of technologies, including Python, Django,
              JavaScript, Shell scripting, Linux, server management, Ansible,
              Docker, CI/CD, Git, and GitHub Actions. Driven by a passion for
              technology, I am committed to continuous learning and staying
              abreast of industry advancements. I take pride in leveraging my
              skills and experience to contribute positively to projects and
              teams, and I look forward to making a meaningful impact in the
              technology landscape.
            </p>
          </div>

          <div className='about-right'>
            <div className='c-box a-box'>
              <Link to='/career'>Career</Link>
            </div>
            <div className='s-box a-box'>
              <Link to='/skills'>Skills</Link>
            </div>
            <div className='p-box a-box'>
              <Link to='/projects'>Projects</Link>
            </div>

            <div className='g-box a-box'>
              <Link to='/gallery'>Gallery</Link>
            </div>
          </div>
        </div>
      </div>
      <Skills />
      <Timeline />
      <Footer />
    </>
  );
}

export default About